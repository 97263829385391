import React, {useEffect} from 'react';
import NProgress from 'nprogress';
import {Box, LinearProgress, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
    },
    box: {
        position: 'absolute',
        top: '50%',
        width: '90%',
    },
}));

export const DisabledOverlay = ({children, className, ...props}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${className}`} {...props}>
            <Box width={'100%'} className={classes.box}>
                {children}
            </Box>
        </div>
    );
};

const LoadingScreen = ({linearProgressProps = {}, ...props}) => {
    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return (
        <DisabledOverlay {...props}>
            <LinearProgress {...linearProgressProps} />
        </DisabledOverlay>
    );
};

LoadingScreen.propTypes = {
    linearProgressProps: PropTypes.object,
};

DisabledOverlay.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default LoadingScreen;
